import React from "react";

import Layout from "../components/layout";

const NotFoundPage = () => (
    <Layout checkAuth={false} seoTitle="404: Not found">
        <h1>NOT FOUND</h1>
    </Layout>
);

export default NotFoundPage;
